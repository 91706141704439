<template>
	<div class="box" style="border-radius: 13px;
    box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;"  >
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card" style="border-radius: 13px;
    box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;"  >
			<div slot="header" class="clearfix">
				<span style="    font-weight: 500;
    font-size: 16px;" >แก้ไขที่อยู่สําหรับจัดส่ง</span>
			</div>

			<div v-loading="loading" class="ns-member-address-list">
				<el-form :model="formData" :rules="rules" ref="ruleForm" label-width="130px">
					<el-form-item label="ชื่อผู้รับสินค้า" prop="name">
						<el-input v-model="formData.name" placeholder="ชื่อของผู้รับสินค้า" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="โทรศัพท์มือถือ" prop="mobile">
						<el-input v-model="formData.mobile" autocomplete="off" placeholder="หมายเลขโทรศัพท์มือถือของผู้รับสินค้า" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="โทรศัพท์">
						<el-input v-model.trim="formData.telephone" autocomplete="off" placeholder="โทรศัพท์（เสริม）" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="ที่อยู่" prop="full_address">
						<el-select v-model="formData.province_id" placeholder="โปรดเลือกจังหวัด" @change="changeProvice(formData.province_id)">
							<el-option v-for="option in province" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
						</el-select>
						<el-select v-model="formData.city_id" placeholder="โปรดเลือกเมือง" @change="changeCity(formData.city_id)">
							<el-option v-for="option in city" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
						</el-select>
						<el-select v-model="formData.district_id" placeholder="โปรดเลือกโซน/เขต" @change="changeDistrict(formData.district_id)">
							<el-option v-for="option in district" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="ที่อยู่โดยละเอียด" prop="address">
						<el-input v-model.trim="formData.address" autocomplete="off" placeholder="เลขที่บ้าน ซอย คอนโด" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item label="ตั้งเป็นที่อยู่หลัก">
						<el-radio-group v-model="formData.is_default">
							<el-radio :label="1">เป็นที่อยู่หลัก</el-radio>
							<el-radio :label="0">ไม่เป็นที่อยู่หลัก</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" size="medium" @click="saveAddress('ruleForm')" style="    font-family: 'Prompt';"  >บันทึก</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
    import { addressInfo, saveAddress } from "@/api/member/member"
    import { getArea } from "@/api/address"

    export default {
        name: "address_edit",
        components: {},
        data() {
            let self = this
            var isMobile = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("หมายเลขโทรศัพท์ไม่สามารถเว้นว่างได้"))
                } else {
                    const reg = /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/

                    if (reg.test(value)) {
                        callback()
                    } else {
                        callback(new Error("โปรดป้อนหมายเลขโทรศัพท์มือถือที่ถูกต้อง"))
                    }
                }
            }

            var fullAddress = (rule, value, callback) => {
                if (self.formData.province_id) {
                    if (self.formData.city_id) {
						if(self.district.length > 0){
							if (self.formData.district_id) {
							    return callback()
							} else {
							    return callback(new Error("โปรดเลือกโซน/เขต"))
							}
						}else{
							return callback()
						}
                    } else {
                        return callback(new Error("โปรดเลือกเมือง"))
                    }
                } else {
                    return callback(new Error("โปรดเลือกจังหวัด"))
                }
            }

            return {
                formData: {
                    id: 0,
                    name: "",
                    mobile: "",
                    telephone: "",
                    province_id: "",
                    city_id: "",
                    district_id: "",
                    community_id: "",
                    address: "",
                    full_address: "",
                    latitude: 0,
                    longitude: 0,
                    is_default: 1
                },
                addressValue: "",
                flag: false, //การระบุป้องกันการทําซ้ํา
                defaultRegions: [],
                rules: {
                    name: [{ required: true, message: "กรุณาใส่ชื่อผู้รับสินค้า", trigger: "blur" }],
                    mobile: [{ required: true, validator: isMobile, trigger: "blur" }],
                    address: [{ required: true, message: "กรุณากรอกที่อยู่แบบเต็มของคุณ", trigger: "blur" }],
                    full_address: [{ required: true, validator: fullAddress, trigger: "blur" }]
                },
                province: [],
                city: [],
                district: [],
                pickerValueArray: [],
                multiIndex: [0, 0, 0],
                isInitMultiArray: false,
                // มีการโหลดรีเจี้ยนเริ่มต้นหรือไม่
                isLoadDefaultAreas: false,
                loading: true,
				yes: true
            }
        },
        created() {
            this.formData.id = this.$route.query.id
            this.getAddressDetail()
            this.getDefaultAreas(0, {
                level: 0
            })
        },
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
        watch: {
            defaultRegions: {
                handler(arr, oldArr = []) {
                    // หลีกเลี่ยงการทริกเกอร์ซ้ํา ๆ เมื่อผ่านเป็นตัวอักษร
                    if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
                    this.handleDefaultRegions()
                },
                immediate: true
            }
        },
        computed: {
            pickedArr() {
                // เริ่ม ต้น
                if (this.isInitMultiArray) {
                    return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
                }
                return [this.pickerValueArray[0], this.city, this.district]
            }
        },
        methods: {
            /**
             * เปลี่ยนจังหวัด
             */
            changeProvice(id) {
                this.getAreas(id, data => (this.city = data))
                let obj = {}
                obj = this.province.find(item => {
                    //นี่มันprovinceนี่คือแหล่งข้อมูลที่สํารวจข้างต้น
                    return item.id === id //กรองข้อมูลที่ตรงกันออก
                })
                this.formData.city_id = ""
                this.formData.district_id = ""
                this.formData.full_address = obj.name // ตั้งค่าที่อยู่ที่เลือก
            },
            /**
             * เปลี่ยนเมือง
             */
            changeCity(id) {
                this.getAreas(id, data => (this.district = data))
                let obj = {}
                obj = this.city.find(item => {
                    //นี่มันprovinceนี่คือแหล่งข้อมูลที่สํารวจข้างต้น
                    return item.id === id //กรองข้อมูลที่ตรงกันออก
                })
                this.formData.district_id = ""
                this.formData.full_address = this.formData.full_address + "-" + obj.name
            },
            /**
             * เปลี่ยนโซน
             */
            changeDistrict(id) {
                let obj = {}
                obj = this.district.find(item => {
                    //นี่มันprovinceนี่คือแหล่งข้อมูลที่สํารวจข้างต้น
                    return item.id === id //กรองข้อมูลที่ตรงกันออก
                })
                this.formData.full_address = this.formData.full_address + "-" + obj.name
            },
            /**
             * รับข้อมูลที่อยู่
             */
            getAddressDetail() {
                addressInfo({
                    id: this.formData.id
                })
                    .then(res => {
                        let data = res.data
                        if (data != null) {
                            this.formData.name = data.name
                            this.formData.mobile = data.mobile
                            this.formData.telephone = data.telephone
                            this.formData.address = data.address
                            this.formData.full_address = data.full_address
                            this.formData.latitude = data.latitude
                            this.formData.longitude = data.longitude
                            this.formData.is_default = data.is_default
                            this.formData.province_id = data.province_id
                            this.formData.city_id = data.city_id
                            this.formData.district_id = data.district_id
                            this.defaultRegions = [data.province_id, data.city_id, data.district_id]
                        }
                    })
                    .catch(err => {})
            },
            // อะซิงโครนัสได้รับภูมิภาค
            getAreas(pid, callback) {
                getArea({
                    pid: pid
                })
                    .then(res => {
                        if (res.code == 0) {
                            var data = []
                            res.data.forEach((item, index) => {
                                data.push(item)
                            })
                            if (callback) callback(data)
                        }
                    })
                    .catch(err => {})
            },

            /**
             * รับรายชื่อจังหวัดและเทศบาล
             */
            getDefaultAreas(pid, obj) {
                getArea({
                    pid: pid
                })
                    .then(res => {
                        if (res.code == 0) {
                            var data = []
                            var selected = undefined
                            res.data.forEach((item, index) => {
                                if (obj != undefined) {
                                    if (obj.level == 0 && obj.province_id != undefined) {
                                        selected = obj.province_id
                                    } else if (obj.level == 1 && obj.city_id != undefined) {
                                        selected = obj.city_id
                                    } else if (obj.level == 2 && obj.district_id != undefined) {
                                        selected = obj.district_id
                                    }
                                }

                                if (selected == undefined && index == 0) {
                                    selected = item.id
                                }
                                data.push(item)
                            })

                            this.pickerValueArray[obj.level] = data
                            if (obj.level + 1 < 3) {
                                obj.level++
                                this.getDefaultAreas(selected, obj)
                            } else {
                                this.isInitMultiArray = true
                                this.isLoadDefaultAreas = true
                            }

                            this.province = this.pickerValueArray[0]
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },

            /**
             * แสดงค่าเริ่มต้น
             */
            handleDefaultRegions() {
                var time = setInterval(() => {
                    if (!this.isLoadDefaultAreas) return
                    this.isInitMultiArray = false

                    for (let i = 0; i < this.defaultRegions.length; i++) {
                        for (let j = 0; j < this.pickerValueArray[i].length; j++) {
                            this.province = this.pickerValueArray[0]

                            // ตรงกับจังหวัด
                            if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
                                // ตั้งค่าจังหวัดที่ตรวจสอบ
                                this.$set(this.multiIndex, i, j)
                                // สอบถามเมือง
                                this.getAreas(this.pickerValueArray[i][j].id, data => {
                                    this.city = data

                                    for (let k = 0; k < this.city.length; k++) {
                                        if (this.defaultRegions[1] == this.city[k].id) {
                                            // ตั้งค่าเมืองที่เลือก
                                            this.$set(this.multiIndex, 1, k)

                                            // สอบถามเขตและมณฑล
                                            this.getAreas(this.city[k].id, data => {
                                                this.district = data

                                                // ตั้งค่าเขตเลือกเขตเลือก
                                                for (let u = 0; u < this.district.length; u++) {
                                                    if (this.defaultRegions[2] == this.district[u].id) {
                                                        this.$set(this.multiIndex, 2, u)
                                                        this.handleValueChange({
                                                            detail: {
                                                                value: [j, k, u]
                                                            }
                                                        })
                                                        break
                                                    }
                                                }
                                            })

                                            break
                                        }
                                    }
                                })
                            }
                        }
                    }
                    if (this.isLoadDefaultAreas) clearInterval(time)
                }, 100)
            },
            handleValueChange(e) {
                // การกําหนดโครงสร้าง
                let [index0, index1, index2] = e.detail.value
                let [arr0, arr1, arr2] = this.pickedArr
                let address = [arr0[index0], arr1[index1], arr2[index2]]

                this.formData.full_address = ""
                for (let i = 0; i < address.length; i++) {
                    if (this.formData.full_address) {
                        this.formData.full_address = this.formData.full_address + "-" + address[i].name
                    } else {
                        this.formData.full_address = this.formData.full_address + address[i].name
                    }
                }
            },

            /**
             * บันทึกที่อยู่
             */
            saveAddress(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var data = {
                            name: this.formData.name,
                            mobile: this.formData.mobile,
                            telephone: this.formData.telephone,
                            province_id: this.formData.province_id,
                            city_id: this.formData.city_id,
                            district_id: this.formData.district_id,
                            community_id: "",
                            address: this.formData.address,
                            full_address: this.formData.full_address,
                            latitude: this.formData.latitude,
                            longitude: this.formData.longitude,
                            is_default: this.formData.is_default
                        }

                        data.url = "add"
                        if (this.formData.id) {
                            data.url = "edit"
                            data.id = this.formData.id
                        }
                        if (this.flag) return
                        this.flag = true

                        saveAddress(data)
                            .then(res => {
                                if (res.code == 0) {
                                    this.$router.push({ path: "/member/delivery_address" })
                                } else {
                                    this.flag = false
                                    this.$notify({ message: res.message, title: "คำเตือน",type: "warning" })
                                }
                            })
                            .catch(err => {
                                this.flag = false
                                this.$notify.error({title: 'Error',message: err.message});
                            })
                    } else {
                        return false
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}
	
	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	
    .el-card.is-always-shadow,
    .el-card.is-hover-shadow:focus,
    .el-card.is-hover-shadow:hover {
        box-shadow: unset;
    }

    .el-card {
        border: 0;
    }

    .ns-len-input {
        width: 350px;
    }

    .el-select {
        margin-right: 10px;
    }
</style>
